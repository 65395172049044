.carousel {
  margin-top: 40px;
  overflow-x: hidden;
  &_caption {
    @extend %bodyCopy;
    margin-top: 20px;
    margin-bottom: 0;
    opacity: 0;
    transition: opacity 400ms linear;
  }
  > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .swiper-slide {
    opacity: 0.2;
    transition: opacity 400ms linear;
    img {
      display: block;
      margin-left:auto;
      margin-right: auto;
      height: auto;
      object-fit: contain;
      background-color: #fff;
    }
  }
  .swiper-slide-active {
    opacity: 1!important;
    .carousel_caption {
      opacity: 1!important;
    }
  }
  .swiper-button-next, .swiper-button-prev {
    background-repeat: no-repeat;
    background-position: center;
    width: 13px;
    height: 21px;
    margin-top: -36px;
    background-size: 13px 21px;
  }
  .swiper-button-prev {
    background-image: url(../_img/icon-arrow-left.svg);
    left: 23.8%;
  }
  .swiper-button-next {
    background-image: url(../_img/icon-arrow-right.svg);
    right: 23.8%;
  }
}