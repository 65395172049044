.genealogy, .detailed-report {
  margin-top: 100px;
  .events-clndr_view-pdf-btn {
    width: 193px;
    &-wrap {
      margin-bottom: 0;
    }
  }
  &_wrap {
    padding: 30px;
    background: #fff;
  }
  &_body-copy {
    @extend %bodyCopy;
    margin-bottom: 0;
    padding-right: 8%!important;
    p {
      margin-bottom: 15px;
    }
  }
  &_input-wrap {
    @extend %bodyCopy;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    position: relative;
    input {
      margin-top: 8px;
    }
  }
  &_pw-input {
    position: relative;
    &.is-auth {
      background-color: #ccf7c5;
      background-image: url(../_img/icon-checkmark.svg);
      background-position: 96% 8px;
      background-size: 38px;
      background-repeat: no-repeat;
    }
  }
  &_error {
    color: red;
    font-size: 1.4rem;
    line-height: 1.4;
    margin-top: 8px;
  }
}