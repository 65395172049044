.checkbox-filters {
  padding-top: 60px;
  .login-form {
    &_checkbox-wrap {
      display: flex;
    }
    &_checkbox-label {
      margin-left: 6px;
    }  
  }
  &_main-tab {
    img {
      margin-left: auto;
    }
    border: 1px solid #979797;
    padding: 15px;
    width: 260px;
    background: #fff;
    cursor: pointer;
    margin-bottom: -1px;
  }
  &_category-title {
    font-size: 1.4rem;
    color: #000;
    font-weight: 500;
    height: 100%;
    min-width: 90px;
  }
  &_label {
    font-size: 1.4rem;
    color: #000;
    font-weight: 500;
    min-width: 101px;
    margin-left: 3px;
  }
  &_checkboxes-wrap {
    background: #fff;
    border: 1px solid #979797;
    padding: 0 30px 0 30px;
  }
  &_filters-row {
    flex-wrap: nowrap;
    padding: 30px 0 15px 0;
    border-bottom: 1px solid $grey;
    font-size: 1.1rem;
    font-weight: 400;
    color: #000;
    .row {
      flex: 1 1 auto;
    }
    .col {
      flex-grow: initial;
      padding-right: 5px;
      padding-left: 5px;
      margin-bottom: 15px;
    }
    &:nth-last-of-type(1) {
      padding-top: 0;
    }
    &:nth-last-of-type(1), &:nth-last-of-type(2) {
      border-bottom: 0;
    }
  }
  &_apply-btn {
    @extend %primaryBtn;
    padding: 15px 30px;
    &:before, &:after {
      content: initial;
    }
  }
}