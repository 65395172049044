.horse-location-page {
  .table {
    &_section-row {
      font-weight: 700;
    }
    &_heading-row {
      .col:nth-of-type(5), .col:nth-of-type(6) {
        flex: 1.5;
      }
    }
    &_item-row {
      .col:nth-of-type(5), .col:nth-of-type(6) {
        flex: 1.5;
      }
      .first-hr {
        transition-delay: 0ms;
      }
      &:hover {
        cursor: pointer;
        background: transparentize($color: $chiefswoodTurq, $amount: 0.85);
      }
    }
  }
}