html,body,p,ol,ul,li,dl,dt,dd,blockquote,figure,fieldset,legend,textarea,pre,iframe,hr,h1,h2,h3,h4,h5,h6 {
  margin:0;
  padding:0;
}
h1,h2,h3,h4,h5,h6 {
  font-weight:normal;
}
ul {
  list-style:none;
}
button,input,select,textarea {
  margin:0;
}
button {
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
}
html {
  box-sizing:border-box;
  -ms-overflow-style: scrollbar;
}
*,*:before,*:after {
  box-sizing:inherit;
}
img,embed,iframe,object,audio,video {
  height:auto;
  max-width:100%;
}
iframe {
  border:0;
}
table {
  border-collapse:collapse;
  border-spacing:0;
}
td,th {
  padding:0;
  text-align:left;
}