.news-article {
  &_pdf-wrap {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: -45px;
    margin-right: -15px;
    margin-bottom: 40px;
    .events-list {
      &_img-thumb {
        width: 200px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
    .events-clndr {
      &_view-pdf-btn-wrap {
        margin-bottom: 20px;
        margin-right: 20px;
      }
      &_view-pdf-btn {
        padding: 17px 17px 13px;
        svg {
          width: 100%;
          height: 50px;
        }
      }
    }
  }  
  &_date {
    color: #242424;
    font-size: 1.4rem;
    margin-bottom: 15px;
  }
  &_title {
    color: #000;
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &_header {
    margin-bottom: 60px;
  }
  &_body {
    p {
      @extend %bodyCopy;
      margin-bottom: 30px;
    }
  }
  &_subtitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  &_img-wrap {
    margin-top: 60px;
    margin-bottom: 60px;
    img {
      width: 100%;
      cursor: pointer;
    }
    figcaption {
      color: #666;
      font-size: 1.6rem;
      margin-top: 15px;
    }
  }
}

.back-button {
  margin-top: 60px;
  margin-bottom: 60px;
  &_btn {
    font-size: 1.5rem;
    color: #565656;
    img {
      margin-right: 8px;
    }
  }
}