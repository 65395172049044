html {
  font-size: 62.5%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $primaryFont;
  width: 100%;
  height: 100%;
  background-color: $pageContBG;
}

#root {
  position: relative;
}

:focus {
  outline: none;
}

::-webkit-scrollbar {
  background: #fff;
  width: 11px;
}

::-webkit-scrollbar-thumb {
  border-right: 3px solid #fff;
  border-left: 3px solid #fff;
  background: #d8d8d8;
}

/* ::-webkit-scrollbar-track {
  width: 5px;
  background: #fff;
} */

a {
  text-decoration: none;
  display: inline-block;
}

.text-link {
  cursor: pointer;
  color: #008E81;
  font-size: 1.5rem;
  font-weight: 700;
}

button,
input {
  font-family: $primaryFont !important;
}

.section-heading {
  @extend %pageHeading;
  margin-bottom: 30px;
}

input[type='text'],
input[type='password'] {
  @extend %inputText;
  padding: 18px 15px 17px;
  border: 1px solid #f1f1f1;
  border-radius: 0;
  width: 100%;
}

.container-fluid {
  padding-right: $containerPadRight-lg;
  padding-left: $containerPadLeft-lg;
}

main:not(.login-page) {
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
  padding-top: 119px;
  padding-bottom: 140px;
  position: relative;
}

.pos-rel {
  position: relative;
  width: 100%;
  height: 100%;
}

.dollar-sign-margin {
  margin-right: 10px;
}

.max-w-1100px {
  max-width: 1100px;
}

.min-h-550px {
  min-height: 550px;
}

.max-w-480px {
  max-width: 480px;
}

.gutters-20px {
  margin-right: -10px;
  margin-left: -10px;

  > .col,
  > [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.spacer-15px {
  width: 15px;
  min-width: 15px;
  min-height: 1px;
  visibility: hidden;
  display: inline-block;
}

.pad-left-15px {
  padding-left: 15px;
}

.pad-x-15px {
  padding-left: 15px;
  padding-right: 15px;
}

.fade-right {
  position: relative;
  &:before {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% + 3px);
    width: 30px;
    background: linear-gradient(
      to left,
      $pageContBG 55%,
      rgba(255, 255, 255, 0)
    );
  }
}

.fade-left-right {
  position: relative;
  &:before {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% + 3px);
    width: 15px;
    background: linear-gradient(
      to left,
      $pageContBG 35%,
      rgba(255, 255, 255, 0)
    );
  }
  &:after {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 3px);
    width: 15px;
    background: linear-gradient(
      to right,
      $pageContBG 35%,
      rgba(255, 255, 255, 0)
    );
  }
}
