.horses-page {
  .table {
    &_item-row {
      &:hover {
        cursor: pointer;
        background: transparentize($color: $chiefswoodTurq, $amount: 0.85);
      }
    }
    &_item-row {
      .first-hr {
        transition-delay: 0ms;
      }
    }
  }
  .table-tabs {
    &_year-tab {
      flex: 1 0 auto;
    }
  }
}