.breeding-page {
  .breeding-table {
    .table {
      &_heading-row {
        .col:nth-of-type(2), .col:nth-of-type(3) {
          flex: 1.5;
        }
      }
      &_item-row {
        .col:nth-of-type(2), .col:nth-of-type(3) {
          flex: 1.5;
        }
        &:hover {
          cursor: pointer;
          background: transparentize($color: $chiefswoodTurq, $amount: 0.85);
        }
      }
    .first-hr {
        transition-delay: 0ms;
      }
    }
    .table-tabs_year-tab {
      padding: 0;
    }
  }
  .rbc-event {
    background: $breedingOrange;
  }
  .rbc-show-more {
    color: $breedingOrange;
  }
  .events-clndr {
    &_additional-row {
      padding-right: 0;
    }
    &_additional-col-2 {
      padding-right: 30px!important;
      padding-top: 30px!important;
      padding-bottom: 30px!important;
      overflow-y: scroll;
      height: 100%;
      justify-content: flex-start!important;
    }
  }
}
