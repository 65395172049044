.newsfeed-list {
  &_item {
    margin-bottom: 30px;
    display: block;
    .nav_svg-fill {
      fill: #000;
    }
    svg {
      margin-bottom: 15px;
    }
  }
  &_img-wrap {
    overflow: hidden;
  }
  &_img {
    max-height: 100%;
    width: 100%;
    margin-bottom: -5px;
    background-color: #fff;
  }
  &_text-wrap {
    background: #fff;
    padding: 30px;
  }
  &_title-wrap {
    margin-bottom: 20px;
  }
  &_title {
    @extend %articleHeading;
  }
  &_body {
    @extend %bodyCopy;
    margin-bottom: 30px;
    p {
      margin-bottom: 15px;
    }
  }
  &_date {
    @extend %tableCellLabel;
  }
}