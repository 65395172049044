.family-tree {
  margin-top: 100px;
  &_scroll-indic {
    @extend %bodyCopy;
    margin-top: 10px;
  }
  &_wrap {
    @extend %bodyCopy;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    background: #fff;
    border: 1px solid $chiefswoodTurq;
    height: 300px;
    > .col {
      padding-left: 30px;
      padding-right: 30px;
      overflow: hidden;
    }
    > .col:not(:first-of-type) {
      border-left: 1px solid $chiefswoodTurq;
    }
    .col .col {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      &:not(:last-of-type):before {
        content: '';
        position: absolute;
        width: 200%;
        height: 1px;
        bottom: 0;
        left: 50%;
        transform: translatex(-50%);
        background-color: $chiefswoodTurq;
      }
    }
  }
}