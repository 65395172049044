.stallion-page {
  &_message {
    width: 200px;
    padding: 5px;
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 2em;
  }
&_button {
  padding: 10px 10px;
  border-radius: 3px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
}
