
.dropdown {
  &_label {
    @extend %sectionHeading;
    margin-left: 1em;
  }
  
  &_container {
  margin: 20px;
  font-family: inherit;
    font-size: 1.6rem;
    font-weight: 400;
  }

  &_select {
    width: 200px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 1em;
  }

  &_select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
}