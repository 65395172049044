// Large laptop
@media (max-width: 1440px) and (max-height: 900px) {

}
// Small laptop
@media (max-width: 1366px) and (max-height: 768px) {
  
}
// Bootstrap mx
@media (max-width: 1477px) {

}
// Bootstrap lg
@media (max-width: 1199px) {

}
// Bootstrap md
@media (max-width: 991px) {

}
// Layout specific
@media (max-width: 821px) {
  .table-tabs_year-tab {
    font-size: 1.7rem;
  }
}
@media (max-height: 768px) {
  .login-form {
    &_main-container {
      padding: 65px 30px 0;
    }
  }
}
@media (max-height: 650px) {
  .login-form {
    &_main-container {
      padding: 10px 30px 0;
    }
    &_banner {
      padding-bottom: 13px;
    }
    &_btn-wrap {
      margin-top: 0;
    }
  }
  .step-forgot-1 {
    .login-form {
      &_btn-wrap {
        margin-top: 0;
      }
    }
  }
}
// Short Laptop
@media (max-height: 768px) and (min-width: 1200px) {
  @import './_responsive/shortLaptop';
}
// Tablet
@media (min-width: 768px) and (max-width: 1199px) {
  @import './_responsive/tablet';
} 
// Mobile / Bootstrap sm
@media (max-width: 767px) {
  @import './_responsive/mobile';
}
// Mobile landscape 
@media (max-width: 768px) and (orientation: landscape) {

}
// Bootstrap xs
@media (max-width: 575px) {
  
}
// iPhone 5/SE
@media (max-width: 320px) {
  .login-form_disclaimer {
    padding-top: 10px;
  }
}