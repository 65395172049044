.id-nums {
  margin-top: 60px;
  &_resize-wrap {
    width: 50.5%;
    padding: 30px 0;
    background: #fff;
    border-top: 3px solid $chiefswoodTurq;
  }
  &_resize-inner {
    width: 95%;
  }
  &_num {
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: #565656;
    font-weight: 400;
    margin-bottom: 11px;
    &:last-of-type {
      margin-bottom: 0;
    }
    span {
      &:first-of-type {
        color: $textColorLightDark;
      }
      color: #0D0D0D;
      font-size: 1.6rem;
    }
  }
}