.results-overview {
  &_top {
    background-image: url(../_img/trophy-overlay.png);
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-color: #fff;
    padding: 30px;
    margin-left: 0;
    margin-right: 0;
    > div {
      min-height: 75px;
      padding-left: 20px;
      border-left: 3px solid $chiefswoodTurq;
      &:first-of-type {
        border: 0;
      }
    }
  }
  &_num {
    font-size: 3rem;
    font-weight: 700;
    color: #000;
    &.is-main {
      color: $chiefswoodTurq;
      margin-bottom: 5px;
    }
    &.is-white {
      color: #fff!important;
    }
  }
  &_label {
    @extend %tableCellLabel;
    font-size: 1.5rem;
    &.is-main {
      color: $textColorDark;
    }
    &.is-white {
      color: #fff!important;
    }
  }
  &_main-total {
    background: $chiefswoodTurq!important;
  }
  &_bot {
    min-height: 100px;
    border-top: 3px solid $chiefswoodTurq;
    margin-left: 0;
    margin-right: 0;
    > div {
      background-color: #fff;
    }
    .table_view-note-btn {
      margin-top: 8px;
    }
    .results-overview_label {
      color: $textColorDark;
    }
    .results-overview_num {
      font-size: 2rem;
    }
  }
  &_col-labels {
    padding-right: 14px;
    margin-bottom: 15px;
  }
  &_col-label {
    color: #565656;
    font-weight: 500;
    font-size: 2rem;
    min-width: 130px;
    &:first-child {
      min-width: 90px;
    }
    &.is-name-col {
      min-width: 160px;
    }
  }
  &_breakdown {
    background: #fff;
    padding: 30px 0 45px;
    margin-right: 0;
    margin-left: 0;
    .tally_list-row {
      margin-bottom: 8px;
    }
    .tally_list-dot-col {
      margin: 0 15px; 
    }
    .tally_list-val-col {
      min-width: 130px;
      &:first-child {
        min-width: 90px;
      }
      &.is-name-col {
        min-width: 160px;
      }
    }
  }
  &_breakdown-title {
    @extend %pageHeading;
    margin-bottom: 5px;
  }
  &_breakdown-sub-title {
    @extend %bodyCopy;
    margin-bottom: 30px;
  }
  &_scroll-wrap {
    max-height: 320px;
    overflow-y: scroll;
  }
}