.produce-record {
  .table_item-row {
    .col:first-child {
      font-weight: 500;
      flex: 0.75;
    }
    .col:nth-of-type(2), .col:nth-of-type(4) {
      flex: 1.5
    }
    &:hover {
      cursor: pointer;
      background: transparentize($color: $chiefswoodTurq, $amount: 0.85);
    }
  }
  .table_heading-row {
    .col:first-of-type {
      flex: 0.75;
    }
    .col:nth-of-type(2), .col:nth-of-type(4) {
      flex: 1.5
    }
  }
}

.horses-detail-page {
  .racing-results_item {
    &:hover {
      background: initial;
      cursor: initial;
    }
  }
}