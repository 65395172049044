.loading-splash {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  svg path,
  svg rect {
    fill: $chiefswoodTurq;
  }
}