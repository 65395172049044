.search {
  padding-top: 60px;
  padding-bottom: 60px;
  &_input {
    font-size: 1.6rem!important;
    padding-left: 57px!important;
    padding-right: 57px!important;
    border: 1px solid #E7E7E7!important;
    margin-bottom: -1px!important;
    background-image: url(../_img/icon-search.svg);
    background-position: 22px 18px;
    background-repeat: no-repeat;
  }
  &_predictions {
    background: #fff;
    max-height: 285px;
    overflow-y: scroll;
    padding: 24px 0;
    border: 1px solid #E7E7E7;
  }
  &_prediction {
    background-image: url(../_img/icon-search.svg);
    background-position: 22px 8px;
    background-repeat: no-repeat;
    padding: 7px 24px 7px 57px;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 400;
    color: #0D0D0D;
    &:hover {
      background-color: #F0F0F0;
      cursor: pointer;
    }
    &:active, &:focus, &.is-active {
      background-color: #F0F0F0;
    }
  }
  &_clear-btn {
    position: absolute;
    top: 1px;
    right: 16px;
    height: 54px;
    width: 53px;
    background-image: url(../_img/icon-close.svg);
    background-repeat: no-repeat;
    background-position: 50% 22px;
    background-color: #fff;
    &:hover {
      background-color: #F0F0F0;
    }
  }
}
//firefox only
@-moz-document url-prefix() {
  .search_clear-btn {
    height: 50px;
    width: 53px;
  }  
}