.tally {
  hr {
    margin-top: 60px;
    border: 0;
    border-bottom: 1px solid #D9D9D9;
  }
  &_main-total-wrap {
    background-image: url(../_img/horse-head-overlay.png);
    background-repeat: no-repeat;
    background-position: 0 50%;
    padding: 30px;
    background-color: $chiefswoodTurq;
  }
  &_regional-total-wrap {
    background: #fff;
    padding: 30px 30px 0 30px;
    &:last-of-type {
      padding-bottom: 30px;
      padding-top: 15px;
    }
  }
  &_total-label {
    font-size: 1.4rem;
    font-weight: 500;
    &.is-main {
      color: $textColorWhite;
    }
    &.is-regional {
      color: $textColorDark;
    }
  }
  &_total-val {
    font-weight: 700;
    &.is-main {
      font-size: 3rem;
      color: $textColorWhite;
      min-height: 37px;
    }
    &.is-regional {
      font-size: 2.5rem;
      color: $textColorDark;
      min-height: 30px;
    }
  }
  &_barns-title {
    @extend %sectionHeading;
    padding-bottom: 8px;
  }
  &_barns-list {
    height: 199px;
    overflow-y: scroll;
    padding: 30px 30px 15px;
    background: #fff;
    border-top: 3px solid $chiefswoodTurq;
  }
  &_list-row {
    @extend %bodyCopy;
    margin-bottom: 1px;
    &:last-child {
      margin-bottom: 15px; 
    }
    &:nth-of-type(2n) {
      opacity: 0.7;
    }
  }
  &_list-dot-col {
    overflow: hidden;
    letter-spacing: 3px;
    white-space: nowrap;
    margin-left: 15px;
  }
  &_list-val-col {
    min-width: 25px;
  }
}