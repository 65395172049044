.status-box {
  margin-top: 100px;
  .table_item-note {
    height: auto;
  }
  hr {
    border: 0;
    border-top: 1px solid $grey;
    width: 100%;
    padding-bottom: 30px;
  }
  &_wrap {
    background: #fff;
    padding: 30px 15px;
  }
  &_heading {
    @extend %tableCellLabel;
    margin-bottom: 5px;
  }
  &_cell {
    @extend %bodyCopy;
    margin-bottom: 0;
    p {
      margin-bottom: 15px;
    }
  }
  &_item {
    margin-bottom: 30px;
  }
}