nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 800;
  background: #fff;
  box-shadow: 0 3px 5px -5px rgba(0,0,0,0.5);
}

.nav {
  &_top-bar {
    z-index: 1;
    position: relative;
    padding-top: 9px;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey;
  }
  &_bot-bar {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &_user-name {
    &:hover {
      cursor: pointer;
    }  
  }
  &_userinfo-wrap {
    position: relative;
    font-size: 1.4rem;
    font-weight: 400;
    margin-right: 15px;
    color: #565656;
  }
  &_user-menu {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 6px 0;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
  }
  &_user-option {
    padding: 6px 35px;
    &:hover {
      cursor: pointer;
      background: transparentize($color: $chiefswoodTurq, $amount: 0.85);
    }
  }
  &_svg-fill {
    fill: #D9D9D9;
  }
  &_page-link {
    @extend %pageLink;
    height: 44px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 30px;
    text-align: center;
    align-items: center;
    &.is-active {
      .nav_svg-fill {
        fill: $chiefswoodTurq;
      }
    }
    &:hover, &:active, &:focus {
      .nav_svg-fill {
        fill: $chiefswoodTurq;
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}