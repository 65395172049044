.racing-results {
  margin-top: 100px;
  .table_note-body {
    padding-right: 50px;
    min-height: 57px;
  }
  .w-100 {
    margin-bottom: 30px;
  }
  hr {
    border: 0;
    border-bottom: 1px solid $grey;
    width: 100%;
    padding-top: 30px;
  }
  .is-main-name {
    font-weight: 500;
    color: #000000;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 10px;
  }
  .is-main-location {
    font-weight: 400;
    color: #000000;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  &_details-item {
    .w-100 {
      margin-bottom: 0;
    }
  }
  &_wrap {
    background: #fff;
    padding: 0;
  }
  &_item {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    &:hover {
      cursor: pointer;
      background: transparentize($color: $chiefswoodTurq, $amount: 0.85);
    }
    .text-link {
      position: relative;
      z-index: 1;
      svg {
        margin-left: 7px;
        position: relative;
        bottom: 2px;
        width: 14px;
        height: 18px;
        vertical-align: middle;    
      }
    }
    &.is-active {
      .table_view-note-btn {
        img {
          transform: rotate(180deg);
        }
      }
      &:last-of-type {
        hr {
          visibility: visible;
        }
      }
    }
    &:last-of-type {
      hr {
        visibility: hidden;
      }
    }
  }
  &_item:last-of-type.is-active {
    .last-hr {
      visibility: hidden;
    }
  }
}