//base
%logInHeading {
  font-size: $logInHeadingSize-sm;
}
main:not(.login-page) {
  padding-top: 45px;
  padding-bottom: 60px;
}
.container-fluid {
  padding-right: $containerPadRight-sm;
  padding-left: $containerPadLeft-sm;
}
.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
}
.swiper-pagination-bullet-active {
  background: $chiefswoodTurq;
}
footer {
  text-align: center;
}
.dollar-sign-margin {
  margin-right: 5px;
}
.back-button {
  margin-top: 30px;
  margin-bottom: 40px;
}
//pages
.login-page {
  footer {
    position: relative;
  }
}
.horse-location-page {
  .breeding-table {
    margin-top: 0;
  }
  .table {
    &_heading-row {
      .col {
        flex: 1 1 auto!important;
      }
    }
    &_item-row {
      .col {
        flex: 1 1 auto!important;
      }
      > div > .col:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.horses-page {
  .breeding-table {
    margin-top: 0;
  }
  .table-tabs {
    padding-right: 0;
    padding-left: 0;
    .swiper-container {
      padding-bottom: 3px;
    }
    &_row-1 {
      margin-left: 0;
      margin-right: 0;
    }
    &_year-tab {
      border-bottom: 3px solid $chiefswoodTurq;
    }
  }
}
.racing-results-page {
  .racing-results {
    margin-top: 30px;
  }
}
.horses-detail-page {
  .racing-results {
    margin-top: 60px;
  }
}
.breeding-page {
  .events-clndr {
    &_additional-row hr {
      margin-top: 6px;
    }
    &_additional-col-2 {
      height: initial!important;
      overflow-y: auto;
      overflow-x: scroll;
      padding-right: 20px!important;
      padding-top: 20px!important;
      padding-bottom: 0!important;
    }
    &_view-pdf-btn-wrap {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
//components
.login {
  &_splash {
    right: initial;
    left: $containerPadLeft-sm;
    width: 100%;
    .max-w-480px {
      max-width: initial;
    }
  }
  &_expired-msg {
    top: 85px;
    right: 50%;
    width: 95%;
    transform: translateX(50%);
    padding: 15px 30px;
    &-enter {
      transform: translatex(150%);
      opacity: 0.01
    }
    &-enter-active {
      transition: transform 300ms linear, opacity 300ms linear;
      transform: translatex(50%);
      opacity: 1;
    }
  }
}
.login-form {
  width: 100vw;
  min-height: #{'calc(100vh - 56px)'};
  .row,
  .container-fluid {
    height: auto;
  }
  &-enter-active {
    position: relative;
  }
  &-enter-done {
    position: relative;
    top: initial;
    right: initial;
  }
  &_main-container {
    padding-bottom: 30px;
    padding-top: 20px;
    height: 100% !important;
  }
  &_banner {
    padding-bottom: 30px;
  }
  &_btn-wrap {
    margin-top: 30px;
  }
  &_mobile-logo {
    margin-bottom: 30px;
  }
}
.nav {
  .hamburger {
    padding-top: 6px;
    padding-bottom: 4px;
  }
  &_bot-bar {
    display: none;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    &-enter {
      display: block;
    }
    &-enter-active {
      display: block;
      opacity: 1;
    }
    &-enter-done {
      display: block;
      opacity: 1;
    }
    &-exit {
      opacity: 1;
      display: block;
    }
    &-exit-active {
      opacity: 0;
      display: block;
    }
    &-exit-done {
      display: none;
    }
    &.is-active {
      display: block;
    }
    > .row {
      height: 100%;
      background: #fff;
    }
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 45px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
  &_page-link {
    svg {
      margin-bottom: 10px;
    }
    &:nth-of-type(1) {
      border-top: 0;
    }
    &:nth-of-type(2) {
      border-top: 0;
    }
    border-top: 1px solid $grey;
    border-right: 1px solid $grey;
    justify-content: center;
    width: 50%;
    height: 25%;
    margin-right: 0;
  }
}
.page-header {
  padding-top: 30px;
  padding-bottom: 30px;
}
.rbc-month-view {
  display: none;
}
.rbc-toolbar {
  position: relative;
  z-index: 1;
}
.events-clndr {
  &_list-heading {
    padding-top: 0;
  }
  &_list-col-wrap {
    padding-top: 55px;
    padding-left: 0;
    padding-right: 0;
    min-height: 675px;
  }
  &_event-item {
    padding: 0 15px 18px 20px;
  }
  &_list-icon {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 0;
    width: 36px;
    height: 38px;
  }
  &_info {
    padding-right: 5px;
    h4 {
      margin-bottom: 2px;
    }
    p {
      margin-top: 2px;
    }
  }
  &_additional-item {
    padding: 19px 20px 18px 20px;
  }
  &_additional-row {
    hr {
      border: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $grey;
      width: 100%;
    }
    padding: 0 20px;
  }
  &_additional-col-1 {
    padding: 0 !important;
    border-right: 0;
  }
  &_additional-col-2 {
    padding: 0 !important;
    padding-top: 20px !important;
    width: 100%;
    order: -1;
  }
  &_view-pdf-btn-wrap {
    margin-bottom: 0;
  }
  &_view-pdf-btn {
    svg {
      height: 50px;
    }
  }
}
.table-tabs {
  &_row-1 {
    margin-top: 20px;
  }
  &_mobile-slider-wrap {
    overflow: hidden;
    width: calc(100% - 84px) !important;
  }
  &_year-tab {
    padding: 0;
    text-align: center;
    height: 45px;
  }
}
.table {
  &_item-row {
    .col:first-child {
      font-weight: 400;
      div:last-child {
        font-weight: 500;
      }
    }
    > div > .col {
      flex-basis: auto;
      margin-bottom: 11px;
      div {
        &:first-of-type {
          width: 45%;
        }
        &:last-of-type {
          width: 55%;
          text-align: left;
        }
      }
    }
  }
  &_view-note-btn {
    margin-bottom: 0 !important;
    margin-top: 15px;
  }
}
.tally {
  hr {
    margin-top: 30px;
  }
  .swiper-container {
    padding-bottom: 20px;
  }
  .swiper-pagination {
    bottom: 0 !important;
  }
  &_barns-wrap {
    height: auto;
    height: 232px;
  }
  &_barns-list {
    height: 150px;
  }
}
.checkbox-filters {
  padding-top: 30px;
  &_category-title {
    margin-bottom: 15px;
  }
  &_filters-row {
    &:nth-last-of-type(1) {
      padding-top: 0;
    }
    .col {
      flex-basis: auto;
      margin-bottom: 10px;
      padding-left: 0;
      padding-right: 0;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.search {
  padding-top: 0;
  padding-bottom: 30px;
}
.results-overview {
  .swiper-container {
    padding-bottom: 32px;
  }
  .swiper-slide {
    background-image: url(../_img/trophy-overlay.png);
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
  }
  .swiper-wrapper {
    height: 141px;
  }
  &_top-item {
    padding-left: 20px;
    border-left: 3px solid $chiefswoodTurq;
    max-height: 81px;
  }
  &_total-races {
    border: 0 !important;
    text-align: center;
  }
  &_main-total {
    padding: 37px 0;
  }
  &_label {
    font-size: 1.5rem;
  }
  &_bot-item {
    padding: 25px 0;
    .results-overview_label {
      margin-bottom: 10px;
    }
    > .col {
      flex-basis: auto;
    }
  }
  &_breakdown {
    padding: 30px 0;
  }
  &_col-labels {
    padding-right: 8px;
    .col {
      display: none;
    }
  }
  &_col-label {
    min-width: initial !important;
  }
  &_scroll-wrap {
    padding-right: 2px;
    padding-left: 2px;
    .tally_list-row {
      font-size: 1.4rem;
      margin-bottom: 11px;
      margin-left: -2px;
      margin-right: -2px;
      .col-4 {
        padding-right: 2px;
        padding-left: 2px;
      }
    }
    .tally_list-dot-col {
      display: none;
    }
    .tally_list-val-col {
      min-width: initial !important;
    }
  }
}
.racing-results {
  .status-box_heading {
    width: 45%;
  }
  .status-box_cell {
    width: 55%;
    text-align: left;
    line-height: initial;
    &.is-main-location {
      padding-top: 4px;
    }
  }
  &_item {
    .spacer-15px {
      display: none;
    }
    .w-100 {
      display: none;
    }
    .col {
      flex-basis: auto;
      margin-bottom: 11px;
      padding: 0 15px;
      &:nth-last-of-type(2) {
        margin-bottom: 0;
      }
    }
  }
  &_details-item {
    .table_note-body {
      text-align: left;
      padding-right: 0;
      width: 100%;
    }
    .last-hr {
      order: 2;
    }
    .col {
      &:nth-of-type(2) {
        display: block !important;
        order: 1;
        margin-bottom: 0;
        margin-top: 4px;
      }
      &:nth-last-of-type(2) {
        margin-bottom: 11px;
      }
    }
  }
}
.license-table {
  margin-top: 0;
  .table_item-row > div > .col {
    div {
      width: 100%;
    }
  }
  .table_heading-row {
    padding: 0;
  }
  &_expiry-col {
    order: -1;
    width: 100%;
    position: absolute;
    top: 20px;
    left: 0;
  }
  &_expiring-soon {
    top: -2px;
    width: 100%;
    font-size: 0.8rem;
    padding: 4px 0;
    text-align: center !important;
  }
}
.events-list {
  &_pdf-wrap {
    padding-left: 15px;
    .events-clndr {
      &_view-pdf-btn-wrap {
        margin-bottom: 15px;
      }
      &_view-pdf-label {
        margin-top: 0;
        line-height: 1.35;
      }
    }
  }
  &_item {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &_img {
    margin-bottom: 15px;
  }
}
.news-article {
  &_pdf-wrap {
    justify-content: flex-start;
  }
}
.newsfeed-list {
  &_item {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.carousel {
  .container-fluid {
    padding-right: 35px;
    padding-left: 35px;
  }
  .swiper-container {
    margin-right: 0;
    margin-left: 0;
    overflow: visible;
  }
  .swiper-slide {
    opacity: 0;
    transition: opacity 200ms linear;
  }
  .swiper-button-next {
    margin-top: -68px;
    right: -25px;
  }
  .swiper-button-prev {
    margin-top: -68px;
    left: -25px;
  }
}
.id-nums {
  .container-fluid {
    padding-right: 35px;
    padding-left: 35px;
  }
  &_resize-wrap {
    width: 100%;
  }
  &_num {
    .col {
      &:first-of-type {
        margin-bottom: 4px;
      }
      flex-basis: auto;
      text-align: center;
    }
  }
}
.family-tree {
  margin-top: 60px;
  &_wrap {
    height: 330px;
    width: 870px;
    line-height: 1.7rem;
    margin-bottom: 10px !important;
  }
  &_scroll-wrap {
    overflow-x: scroll;
  }
}
.status-box {
  margin-top: 60px;
  &_wrap {
    padding-bottom: 0px;
    hr {
      opacity: 0;
      padding-bottom: 0;
      margin-top: 30px;
      transition: opacity 200ms linear;
      transition-delay: 0ms;
    }
    &.is-active {
      hr {
        opacity: 1;
        transition-delay: 200ms;
      }
    }
    > .row {
      div:nth-last-of-type(2) {
        margin-bottom: 0;
      }
    }
  }
  .table_item-note {
    height: 0;
    hr {
      border: 0;
    }
  }
}
.breeding-table {
  .table_item-row {
    > .row {
      .col {
        div:first-of-type {
          font-size: 1.4rem;
          color: #565656;
        }
      }
      .col:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.racing-stats {
  margin-top: 60px;
  .section-heading {
    margin-bottom: 30px;
  }
  .table_heading-row {
    padding: 0;
  }
  .table_item-row {
    > .row {
      .col {
        div:first-of-type {
          font-size: 1.4rem;
          color: #565656;
        }
      }
      .col:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.produce-record {
  margin-top: 60px;
  .section-heading {
    margin-bottom: 30px;
  }
  .table_heading-row {
    padding: 0;
  }
  .table_item-row {
    > .row {
      .col {
        div:first-of-type {
          font-size: 1.4rem;
          color: #565656;
        }
      }
      .col:nth-last-of-type(2) {
        margin-bottom: 0;
      }
    }
  }
}
.genealogy {
  margin-top: 60px;
  &_body-copy {
    padding: 0 !important;
    margin-bottom: 15px;
  }
}
.detailed-report {
  margin-top: 60px;
  .events-clndr_view-pdf-btn-wrap {
    text-align: center;
  }
  .genealogy_wrap {
    align-items: center;
    > .col-auto {
      flex-direction: column;
      width: 100%;
    }
    .detailed-report_input-wrap {
      padding-left: 0;
      margin-top: 15px;
      text-align: center;
    }
  }
}
