.rbc-calendar {
  height: 100%;
}

.rbc-date-cell {
  color: $textColorDark;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  padding-top: 4px;
  padding-left: 6px;
  margin-bottom: 6px;
  position: relative;
  a {
    cursor: default;
    padding: 3px 4px;
  }
  &.rbc-off-range {
    color: lighten($textColorDark, 30);
  }
  &.rbc-now a {
    background-color: $chiefswoodTurq;
    border-radius: 50%;
    color: #fff;
  }
  &.rbc-current {
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      height: 0;
      width: 100%;
      opacity: 0.8;
      border: 2px solid $chiefswoodTurq;
    }
  }
}

.rbc-toolbar {
  margin-bottom: 30px;
}

.rbc-toolbar .rbc-toolbar-label {
  @extend %sectionHeading;
  text-align: left;
  padding-left: 0;
  .rbc-toolbar-label-year {
    font-weight: 300;
    margin-left: 5px;
  }
}

.rbc-show-more {
  font-weight: 600;
  color: $clndrBlue;
}

.rbc-btn-group {
  width: 100%;
  display: flex;
  align-items: center;
  button {
    cursor: pointer;
    border: 0;
    padding: 0;
    width: 12px;
    height: 18px;
    background-repeat: no-repeat;
    &:hover, &:active:hover, &:focus {
      background-color: transparent;
      box-shadow: none;
      border: 0;
      color: $chiefswoodTurq;
    }
    &:first-of-type {
      background-image: url(../_img/icon-arrow-left.svg);
      margin-right: 8px;
    }
    &:nth-of-type(2) {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $chiefswoodTurq;
      }
    }
    &:last-of-type {
      background-image: url(../_img/icon-arrow-right.svg);
      margin-left: 8px;
    }
  }
}

.rbc-header + .rbc-header {
  border-left: 0;
}

.rbc-month-view {
  border: 2px solid $grey;
  border-top: 0;
  height: 70vh;
  max-height: 800px;
  min-height: 580px;
  .rbc-header {
    border: 0;
  }
}

.rbc-month-header {
  background-color: $chiefswoodTurq;
  .rbc-header {
    padding: 10px 0;
    font-size: 1.3rem;
    font-weight: 800;
    color: $textColorWhite;
    background-color: $chiefswoodTurq;
    &:first-of-type, &:last-of-type {
      background-color: #17988C;
      position: relative;
      z-index: 1;
    }
    &:first-of-type {
      left: -2px;
    }
    &:last-of-type {
      right: -2px;
    }
  }
}

.rbc-month-row {
  .rbc-row-bg {
    .rbc-day-bg {
      background-color: #fff;
      &:first-child, &:last-child {
        background-color: #f4f4f4
      }
    }
  }
}

.rbc-month-row + .rbc-month-row {
  border-top: 2px solid $grey;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 2px solid $grey;
}

.rbc-row-content {
  height: 100%; 
}

.rbc-row-segment {
  text-align: right;
  padding: 1px 2px;
}

.rbc-event {
  position: relative;
  transition: all 0.1s linear;
  background: $clndrBlue;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 2px 5px 3px;
  border-radius: 4px;
  &:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  opacity: 0;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3);
  transition: opacity 0.1s linear;
  }
  &:focus {
    outline: none;
  }
  &.is-potential {
    background: #b51a1a;
    &.rbc-selected {
      background: #b51a1a;
    }  
  }
  &.rbc-selected {
    background: $clndrBlue;
    &:before {
      opacity: 1;
    }
    filter: brightness(1.1);
    .rbc-event-content {
      position: relative;
      left: 3px;
    }
  }
}

.events-clndr {
  &_event {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &_filter-bar {
    width: 100%;
    height: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-input-wrap {
      &.is-race-filter {
        label:before {
          background: color('race');
        }
      }
      &.is-breeding-filter {
        label:before {
          background: color('breeding');
        }
      }
      &.is-event-filter {
        label:before {
          background: color('event');
        }
      }
      &.is-license-filter {
        label:before {
          background: color('license');
        }
      }
      padding: 0;
      input {
        position: absolute;
        z-index: -1;
        visibility: hidden;
        &:checked ~ label {
          &:after {
            content: '✔';
          }
        }
      }
      label {
        cursor: pointer;
        position: relative;
        font-size: 1.2rem;
        padding-left: 45px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 30px;
          height: 30px;
          border-radius: 8px;
          background: red;
        }
        &:after {
          content: '';
          position: absolute;
          left: 5px;
          top: 5px;
          width: 25px;
          height: 25px;
          font-size: 1.2rem;
          color: #fff;
        }
      }
    }
  }
  &_no-events-msg {
    @extend %bodyCopy;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    height: 89px;
    width: 100%;
  }
  &_list-heading {
    @extend %sectionHeading;
    padding-top: 16px;
  }
  &_list-icon {
    padding-right: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    width: 66px;
    height: 38px;
  }
  &_events-list {
    position: relative;
    border-top: 3px solid $chiefswoodTurq;
    background-color: #fff;
    height: 70vh;
    max-height: 800px;
    min-height: 580px;
    margin-top: 14px;
    overflow-y: scroll;
    box-sizing: border-box;
  }
  &_top-border {
    width: 100%;
    margin-bottom: 18px;
    height: 1px;
    background-color: $grey;
  }
  &_event-item {
    z-index: 1;
  }
  &_event-item, &_additional-item {
    padding: 0 20px 18px 20px;
    background-color: #fff;
    position: relative;
    font-size: 1.6rem;
    cursor: pointer;
    border-bottom: 0;
    &:first-of-type {
      .events-clndr_top-border {
        visibility: hidden;
      }
    }
    &.is-child-event {
      .events-clndr_top-border {
        display: none;
      }
      .events-clndr_date-wrap {
        visibility: hidden;
      }
    }
  }
  &_additional-top-border {
    width: 95%;
    top: -1px;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    height: 1px;
    background-color: $grey;
  }
  &_event-additional {
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
  }
  &_additional-row {
    position: relative;
    height: calc(100% - 89px);
    padding: 0 30px;
  }
  &_additional-item {
    padding: 19px 35px 18px 20px;
  }
  &_additional-col-1 {
    padding: 30px 30px 0 0!important;
    height: 100%;
    overflow-y: scroll;
    border-right: 2px solid $grey;
  }
  &_additional-col-2 {
    padding: 0 0 0 30px!important;
  }
  &_additional-label {
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: #565656;
    margin-bottom: 6px;
  }
  &_additional-info {
    color: $textColorDark;
    margin-bottom: 30px;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  &_view-pdf-btn-wrap {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_view-pdf-btn {
    border: 1px solid $grey;
    padding: 34px 22px;
    width: 100%;
    transition: background-color 200ms $easeOut, border-color 200ms $easeOut;
    &:hover {
      background-color: transparentize($grey, 0.75);
      border-color: transparentize($grey, 0.75);
    }
    svg {
      width: 100%;
      height: 90px;
    }
    &.is-disabled {
      background: #e0e0e0;
      border: 1px solid #e0e0e0;
      cursor: not-allowed;
      .events-clndr_view-pdf-fill {
        fill: #6f6f6f;
      }
      .events-clndr_view-pdf-label {
        color: #6f6f6f;
      }
    }
  }
  &_view-pdf-fill {
    fill: $chiefswoodTurq;
  }
  &_view-pdf-label {
    font-weight: 700;
    font-size: 1.4rem;
    color: $chiefswoodTurq;
    margin-top: 15px;
  }
  &_type-tag {
    display: inline-block;
    color: #fff;
    padding: 6px 30px;
    border-radius: 18px;
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  &_info {
    margin-left: 15px;
    h4, p {
      display: inline-block;
    }
  }
  &_date-wrap {
    min-width: 52px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.4rem;
    padding: 8px 12px;
    background: #ECECEC;
    color: $textColorDark;
    &.is-today {
      background: $chiefswoodTurq;
      color: $textColorWhite;
    }
    div:last-child {
      font-weight: 500;
    }
  }
}
