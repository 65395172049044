.events-list {
  .table_view-note-btn {
    margin-top: auto;
    padding-top: 30px;
  }
  .table_item-note {
    @extend %bodyCopy;
    margin-bottom: 0;
    background: #fff;
    padding: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  hr {
    border: 0;
    padding-top: 29px;
    border-bottom: 1px solid $grey;
    width: 100%;
    opacity: 0;
    transition: opacity 100ms linear;
    transition-delay: 200ms;
  }
  &_img-viewer {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 850;
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    .image {
      width: 90%;
      max-width: 1280px;
      background-color: rgba(0,0,0,0.45);
      box-shadow: 0 0 20px rgba(0,0,0,0.65);
    }
  }
  &_img-wrap {
    overflow: hidden;
    margin-right: 15px;
  }
  &_img {
    position: relative;
    max-height: 100%;
    width: 100%;
    background-color: #fff;
  }
  &_item {
    margin-bottom: 30px;
    &.is-active {
      hr {
        opacity: 1;
        transition-delay: 0ms;
      }
    }
  }
  &_pdf-wrap {
    max-width: 150px;
    min-width: 100px;
    padding-left: 30px;
    .events-clndr {
      &_view-pdf-btn-wrap {
        margin-bottom: 20px;
      }
      &_view-pdf-btn {
        padding: 17px 17px 13px;
        svg {
          width: 100%;
          height: 50px;
        }
      }
    }  
  }
  &_img-thumb {
    cursor: pointer;
  }
  &_top {
    padding: 30px 15px 0px 15px !important;
    background: #fff;
  }
  &_main-text-wrap {
    padding: 0 15px 0 15px;
    height: calc(100% - 30px);
  }
  &_title {
    @extend %articleHeading;
    margin-bottom: 15px;
  }
  &_meta-wrap {
    p:first-of-type {
      margin-bottom: 5px;
    }
    @extend %tableCellLabel;
    margin-bottom: 20px;
  }
  &_body {
    @extend %bodyCopy;
    margin-bottom: 0;
  }
}