.login {
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  position: relative;
  &_expired-msg {
    position: fixed;
    top: 45px;
    right: 45px;
    @extend %bodyCopy;
    text-align: center;
    box-shadow: 0 5px 5px rgba(0,0,0,0.5);
    padding: 15px 56px;
    background-color: #fff;
    border-right: 5px solid #008E81;
    border-radius: 2px;
    &-enter {
      transform: translatex(150%);
      opacity: 0.01
    }
    &-enter-active {
      transition: transform 300ms linear, opacity 300ms linear;
      transform: translatex(0);
      opacity: 1;
    }
  }
  &_top-row {
    padding-top: 24px;
    position: absolute;
  }
  &_splash {
    position: absolute;
    bottom: 56px;
    right: $containerPadRight-lg;
  }
  &_heading {
    @extend %logInHeading;
  }
  &_body {
    p {
      @extend %bodyCopy;
      color: $textColorWhite;
      margin-bottom: 10px;
    }
  }
  &_splash-btn {
    @extend %primaryBtn;
    margin-top: 60px;
  }
}

.step-login {
  &-enter {
    transform: translatex(200%)!important;
  }
  &-enter-active {
    transform: translatex(-50%)!important;
    transition: transform 300ms linear;
  }
  &-exit-active {
    transform: translatex(-250%)!important;
    transition: transform 300ms linear;
  }
}

.step-forgot-1 {
  .login-form {
    &_inputs-wrap {
      flex-grow: 0.65;
    }
    &_btn-wrap {
      margin-top: 60px;
    }
  }
}

.step-forgot-end {
  .login-form {
    &_inputs-wrap {
      flex-grow: 0.65;
    }
    &_copy {
      p {
        margin-bottom: 15px;
      }
    }
  }
}

.login-form {
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 56vw;
  height: 100%;
  background: #fff;
  .row, .container-fluid {
    height: 100%;
  }
  &-enter {
    opacity: 0.01;
    transform: translate(50px, 0);
  }
  &-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform 300ms $easeOut;
  }
  &_heading {
    @extend %logInHeading;
    color: $textColorDark;
  }
  &_copy {
    p {
      @extend %bodyCopy;
    }
  }
  &_main-container {
    padding: 115px 30px 0;
    height: calc(100% - 115px)!important;
    overflow-y: scroll;
  }
  &_column {
    position: absolute;
    left: 50%;
    width: 100%;
    padding: 0 15px;
    transform: translatex(-50%);
    max-width: 480px;
    min-height: 425px;
    height: 76%!important;
  }
  &_inputs-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &_text-input {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_checkbox {
    @extend %checkbox;
  }
  &_checkbox-label {
    @extend %inputText;
    display: inline-block;
    margin-left: 12px;
  }
  &_forgot-pass {
    @extend %textLink;
  }
  &_additional {
    display: flex;
    justify-content: space-between;
  }
  &_login-btn {
    @extend %primaryBtn;
  }
  &_btn-wrap {
    margin-top: 60px;
  }
  &_disclaimer {
    color: #0D0D0D;
    font-size: 1.3rem;
    line-height: 1.9rem;
    padding-top: 30px;
  }
  &_incorrect {
    margin-top: 15px;
    color: red;
    font-size: 1.4rem;
    &.is-margin-bot {
      margin-top: -25px;
      margin-bottom: 25px;
    }
  }
}