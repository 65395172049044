.license-table {
  margin-top: -30px;
  &_expiring-soon {
    font-size: 1.4rem;
    font-weight: 700;
    padding: 10px 20px 11px;
    background-color: #E1B930;
    color: #fff;
    display: inline-block;
    position: relative;
    top: 9px;
  }
  &_expiry-col {
    margin-top: -20px;
  }
  &_expired {
    @extend .license-table_expiring-soon;
    background-color: #dc3545;
  }
}