.page-header {
  padding-top: 60px;
  padding-bottom: 60px;
  &_heading {
    @extend %pageHeading;
  }
  &_horse-details {
    margin-top: 10px;
    color: #565656;
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 400;
  }
  &_detail {
    float: left;
    &:first-of-type {
      padding-right: 15px;
      border-right: 1px solid #D9D9D9;
    }
    &:nth-of-type(2) {
      padding-left: 15px;
      padding-right: 15px;
    }
    &:last-of-type {
      padding-left: 15px;
      border-left: 1px solid #D9D9D9;
    }
  }
}