//base
.container-fluid {
  padding-right: 24px;
  padding-left: 24px;
}
//components
.nav {
  &_page-link {
    margin-right: 15px;
    font-size: 1.2rem;
  }
}
.rbc-month-view {
  height: initial;
}
.events-list {
  &_pdf-wrap {
    .events-clndr {
      &_view-pdf-label {
        margin-top: 0;
        line-height: 1.35;
      }
    }
  }
}  
.events-clndr {
  &_events-list {
    height: initial;
  }
  &_event-item {
    padding: 0 20px 18px 20px;
  }
  &_list-icon {
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 46px;
    height: 38px;
  }
  &_additional-row {
    padding: 0 20px;
    hr {
      display: none;
    }
  }
  &_additional-col-2 {
    padding: 20px 0 20px 20px !important;
  }
  &_additional-col-1 {
    padding: 20px 20px 0 0 !important;
  }
  &_view-pdf-btn {
    padding: 20px 20px;
    svg {
      height: 40px;
    }
  }
}
.table {
  &_item-row {
    font-size: 1.3rem;
    > .row {
      > .col {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  &_heading-row {
    font-size: 1.6rem;
    > .col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  &_view-note-btn {
    font-size: 1.1rem;
    img {
      margin-left: 2px;
      width: 11px;
    }
  }
}
.tally {
  &_barns-list {
    padding: 15px 20px;
  }
  &_list-row {
    font-size: 1.4rem;
  }
  &_list-val-col {
    min-width: initial;
  }
  &_list-dot-col {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.checkbox-filters {
  &_checkboxes-wrap {
    padding: 0 20px 0 20px;
  }
  .login-form_checkbox-label {
    font-size: 1.05rem;
    margin-left: 5px;
  }
}
.search {
  padding-top: 40px;
  padding-bottom: 40px;
}
.results-overview {
  &_main-total {
    padding-top: 36px;
    padding-bottom: 36px;
    min-width: 100%;
    .results-overview_label {
      text-align: right;
    }
  }
  &_bot-item {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.carousel {
  .swiper-button-next, .swiper-button-prev {
    margin-top: -55px;
  }
}
.family-tree {
  &_wrap {
    height: 340px;
    line-height: 1.7rem;
  }
}
.produce-record {
  .table_view-note-btn {
    font-size: 1.1rem;
    img {
      margin-left: 3px;
      width: 11px;
    }
  }
}