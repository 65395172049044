.breeding-table {
  margin-top: 60px;
}

.table {
  .table-tabs_estimated-dot {
    margin-right: -10px;
    position: relative;
    left: -12px;
  }
  &_no-results {
    @extend %bodyCopy;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_heading-row {
    @extend %tableHeading;
    padding: 30px 30px 15px 30px;
    .col {
      display: inline-flex;
      align-items: flex-end;
      &:first-of-type {
        flex: 1.5;
      }
    }
  }
  &_sort-alphabetical-btn {
    margin-left: 5px;
    padding: 0 10px;
    cursor: pointer;
    &.is-desc {
      img {
        transform: rotate(180deg);
      }
    }
    img {
      position: relative;
      top: 1px;
    }
  }
  &_item-row {
    position: relative;
    background-color: #fff;
    padding: 30px 15px 0px 15px;
    font-size: 1.6rem;
    font-weight: 400;
    color: #000;
    display: block;
    .first-hr {
      transition: visibility 0ms linear;
      transition-delay: 250ms;
    }
    &.is-active {
      .first-hr {
        visibility: visible;
      }
      .table_view-note-btn {
        img {
          transform: rotate(180deg);
        }
      }
    }
    hr {
      border: 0;
      padding-top: 30px;
      border-bottom: 1px solid $grey;
      width: 100%;
    }
    &:last-child {
      hr {
        visibility: hidden;
      }
      &.is-active {
        .table_item-note {
          hr {
            visibility: hidden;
          }
        }
      }
    }
    .col:first-child {
      font-weight: 500;
      flex: 1.5;
    }
  }
  &_view-note-btn {
    cursor: pointer;
    color: $chiefswoodTurq;
    font-size: 1.4rem;
    font-weight: 700;
    img {
      margin-left: 6px;
      position: relative;
      top: -1px;
    }
  }
  &_item-note {
    height: 0;
    width: 100%;
    overflow: hidden;
    &.is-active {
      hr {
        visibility: visible;
      }
    }
  }
  &_note-label {
    font-size: 1.4rem;
    font-weight: 400;
    color: #565656;
    margin-bottom: 10px;
  }
  &_note-body {
    max-width: 1100px;
    opacity: 0;
    @extend %bodyCopy;
    p {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.table-tabs {
  .w-100 {
    margin-bottom: 0!important;
  }
  &_border-bot {
    border: 0!important;
    border-bottom: 3px solid $chiefswoodTurq!important;
    padding-top: 0!important;
  }
  &_row-1 {
    height: 45px;
    flex-wrap: nowrap;
  }
  &_heading {
    @extend %sectionHeading;
  }
  &_estimated-dot {
    width: 10px !important;
    height: 10px;
    display: inline-block;
    background-color: $breedingOrange;
    border-radius: 50%;
    margin-right: 10px;
  }
  &_estimated-label {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.1rem;
  }
  &_arrow-btn {
    img {
      position: relative;
      top: 2px;
    }
  }
  &_year-tab {
    @extend %sectionHeading;
    padding: 0 24px 0 24px;
    flex: 0 0 auto;
    &.is-active {
      color: #fff;
      background-color: $chiefswoodTurq;
      pointer-events: none;
    }
  }
}
