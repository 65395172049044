$primaryFont: 'Montserrat', sans-serif;
$textColorDark: #242424;
$textColorLightDark: #565656;
$textColorWhite: #fff;

$chiefswoodTurq: #008E81;
$breedingOrange: #F28C29;
$clndrBlue: #316AB0;
$grey: #D9D9D9;
$pageContBG: #F2F3F3;

$easeOut: cubic-bezier(0.110, 0.365, 0.535, 1.000);

// Layout
$containerPadRight-lg: 42px;
$containerPadLeft-lg: 42px;
$containerPadRight-sm: 15px;
$containerPadLeft-sm: 15px;

// logInHeading
$logInHeadingSize-lg: 5rem;
$logInHeadingSize-sm: 3rem;
$logInHeadingWeight: 800;
$logInHeadingColor: $textColorWhite;
$logInHeadingMarginBot-lg: 20px;

%logInHeading {
  font-size: $logInHeadingSize-lg;
  font-weight: $logInHeadingWeight;
  color: $logInHeadingColor;
  margin-bottom: $logInHeadingMarginBot-lg;
}

// pageHeading
$pageHeadingSize-lg: 2.5rem;
$pageHeadingWeight: 500;
$pageHeadingColor: $textColorDark;

%pageHeading {
  font-size: $pageHeadingSize-lg;
  font-weight: $pageHeadingWeight;
  color: $pageHeadingColor;
}

// sectionHeading
$sectionHeadingSize-lg: 2rem;
$sectionHeadingLine-lg: 2.5rem;
$sectionHeadingWeight: 500;
$sectionHeadingColor: $textColorDark;

%sectionHeading {
  font-size: $sectionHeadingSize-lg;
  font-weight: $sectionHeadingWeight;
  line-height: $sectionHeadingLine-lg;
  color: $sectionHeadingColor;
}

// articleHeading
$articleHeadingSize-lg: 1.8rem;
$articleHeadingWeight: 500;
$articleHeadingColor: $textColorDark;

%articleHeading {
  font-size: $articleHeadingSize-lg;
  font-weight: $articleHeadingWeight;
  color: $articleHeadingColor;
}

// tableHeading
$tableHeadingSize-lg: 1.8rem;
$tableHeadingWeight: 500;
$tableHeadingColor: $textColorLightDark;

%tableHeading {
  font-size: $tableHeadingSize-lg;
  font-weight: $tableHeadingWeight;
  color: $tableHeadingColor;
}

// tableCellLabel
$tableCellLabelSize-lg: 1.4rem;
$tableCellColor: $textColorLightDark;

%tableCellLabel {
  font-size: 1.4rem;
  color: $tableCellColor; 
}

// bodyCopy
$bodyCopySize-lg: 1.6rem;
$bodyCopyLine-lg: 2.5rem;
$bodyCopyWeight: 400;
$bodyCopyColor: $textColorDark;

%bodyCopy {
  font-size: $bodyCopySize-lg;
  font-weight: $bodyCopyWeight;
  line-height: $bodyCopyLine-lg;
  color: $bodyCopyColor;
  &:last-child {
    margin-bottom: 0;
  }
}

// pageLink
$pageLinkSize-lg: 1.4rem;
$pageLinkLine-lg: 1.4rem;
$pageLinkWeight: 300;
$pageLinkColor: #000000;

%pageLink {
  font-size: $pageLinkSize-lg;
  font-weight: $pageLinkWeight;
  line-height: $pageLinkLine-lg;
  color: $pageLinkColor;
}

// inputText
$inputTextSize-lg: 1.4rem;
$inputTextLine-lg: 1.4rem;
$inputTextWeight: 400;
$inputTextColor: $textColorDark;

%inputText {
  font-size: $inputTextSize-lg;
  font-weight: $inputTextWeight;
  line-height: $inputTextLine-lg;
  color: $inputTextColor;
}

// primaryBtn
%primaryBtn {
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 800;
  padding: 15px 86px 15px 15px;
  color: $textColorWhite;
  background-color: $chiefswoodTurq;
  border: 0;
  position: relative;
  transition: background-color 200ms linear;
  &:before {
    content: url(../_img/icon-right-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 56%;
    transform: translate(0, -50%);
    right: 15px;
    opacity: 1;
    transition: opacity 100ms ease-out, transform 200ms ease-out;
  }
  &:after {
    content: url(../_img/icon-right-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 56%;
    transform: translate(-10px, -50%);
    right: 15px;
    opacity: 0;
    transition: opacity 100ms ease-out, transform 200ms ease-out;
  }
  &:hover {
    background-color: lighten($chiefswoodTurq, 5);
    &:before {
      opacity: 0;
      transform: translate(15px, -50%);
    }
    &:after {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
  &:active {
    background-color: $chiefswoodTurq;
  }
}

// Safari 10.1+
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  %primaryBtn {
    &:hover {
      &:before {
        opacity: 1;
        transform: translate(0, -50%);
      } 
      &:after {
        opacity: 0;
        transform: translate(-10px, -50%);
      }
    }
  }
}}

// checkbox
%checkbox {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  + label {
    cursor: pointer;
  }
  + label:before {
    content: "\00a0";
    top: 0;
    left: 0;
    border-radius: 3px;
    display: inline-block;
    background-color: $grey;
    height: 15px;
    width: 15px;
    font-size: 12px;
  }
  &:checked + label:before {
    content: "\00a0";
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDYxMi4wMDUgNjEyLjAwNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjEyLjAwNSA2MTIuMDA1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGcgaWQ9InRpY2siPgoJCTxnPgoJCQk8cGF0aCBkPSJNNTk1LjYwMSw4MS41NTNjLTIxLjg5Mi0yMS44OTEtNTcuMzYyLTIxLjg5MS03OS4yNTMsMEwxODMuMDMsNDE0Ljg3bC04OC42MjktNzYuMTMzICAgICBjLTIxLjU5Mi0yMS41OTMtNTYuNTk2LTIxLjU5My03OC4yMDcsMGMtMjEuNTkyLDIxLjU5Mi0yMS41OTIsNTYuNjE0LDAsNzguMjA2bDEzMi40MTIsMTEzLjczMyAgICAgYzIxLjU5MiwyMS41OTMsNTYuNTk2LDIxLjU5Myw3OC4yMDcsMGMyLjE2Ny0yLjE2NiwzLjk3OS00LjU3Niw1LjcxNi02Ljk4NWMwLjMxNy0wLjI5OSwwLjY3Mi0wLjUwNSwwLjk5LTAuODA0bDM2Mi4wODMtMzYyLjEwMSAgICAgQzYxNy40NzMsMTM4LjkxNCw2MTcuNDczLDEwMy40MjUsNTk1LjYwMSw4MS41NTN6IiBmaWxsPSIjRkZGRkZGIi8+CgkJPC9nPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
    background-color: $chiefswoodTurq;
    background-size: 11px 11px;
    background-repeat: no-repeat;
    background-position: center;
    color: $textColorWhite;
    text-align: center;
  }
}

// textLink
$textLinkSize-lg: 1.4rem;
$textLinkLine-lg: 1.8rem;
$textLinkWeight: 800;
$textLinkColor: $chiefswoodTurq;

%textLink {
  font-size: $textLinkSize-lg;
  font-weight: $textLinkWeight;
  line-height: $textLinkLine-lg;
  color: $textLinkColor;
  transition: color 200ms ease-out;
  &:hover {
    color: lighten($textLinkColor, 5);
  }
  &:active {
    color: $textLinkColor;
  }
}